<template>
	<el-dialog
		v-model="isShowDialog"
		width="800px"
		class="dialog-container"
		:title="`${formType === 'add' ? '新增' : '编辑'}运营红线规则`"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="100px"
				size="default"
				:model="formData"
				:rules="formRule"
			>
				<el-form-item label="所属公司" prop="companyId">
					<el-select
						v-model="formData.companyId"
						placeholder="请选择所属公司"
						style="width: 100%"
						filterable
					>
						<el-option
							v-for="(item, index) in companyOptions"
							:key="index"
							:label="item.companyName"
							:value="item.companyId"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="运营红线类型" prop="type">
					<el-select
						v-model="formData.type"
						placeholder="请选择运营红线类型"
						style="width: 100%"
						filterable
						@change="onChangeRedType"
					>
						<el-option
							v-for="(item, index) in allOptinos.redLines"
							:key="index"
							:label="item.name"
							:value="item.code"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="运营红线规则" prop="key2">
					<!-- 车辆状态/应收账单 -->
					<el-row :gutter="5" v-if="formData.type !== 'ActualTradeBill'">
						<el-col :span="8">
							<el-select
								v-model="formData.key2"
								placeholder="请选择运营运营红线规则"
								style="width: 100%"
								filterable
							>
								<el-option
									v-for="(item, index) in allOptinos.redRules[formData.type]"
									:key="index"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-col>
						<el-col :span="8">
							<el-select
								v-model="formData.key3"
								placeholder="请选择运营运营红线规则"
								style="width: 100%"
								filterable
							>
								<el-option
									v-for="(item, index) in allOptinos.compareArr"
									:key="index"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-input
								v-model="formData.key4"
								:placeholder="`请输入${unitOptions.placeholder}`"
								show-word-limit
							/>
						</el-col>
						<el-col :span="1">{{ unitOptions.unit }}</el-col>
					</el-row>
					<!-- 实收账单 -->
					<el-row :gutter="5" v-else>
						<el-col :span="6">
							<el-select
								v-model="formData.key2"
								placeholder="请选择运营红线规则"
								style="width: 100%"
								filterable
							>
								<el-option
									v-for="(item, index) in allOptinos.redRules[formData.type]"
									:key="index"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-col>
						<el-col :span="3">
							<el-select
								v-model="formData.key5"
								placeholder="请选择运营红线规则"
								style="width: 100%"
								filterable
							>
								<el-option
									v-for="(item, index) in allOptinos.calcOpts"
									:key="index"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-col>
						<el-col :span="5">
							<el-input v-model="formData.key4" placeholder="请输入" show-word-limit />
						</el-col>
						<el-col :span="4">
							<el-select
								v-model="formData.key3"
								placeholder="请选择运营红线规则"
								style="width: 100%"
								filterable
							>
								<el-option
									v-for="(item, index) in allOptinos.compareArr"
									:key="index"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-col>
						<el-col :span="6">
							<el-select
								v-model="formData.key6"
								placeholder="请选择运营红线规则"
								style="width: 100%"
								filterable
							>
								<el-option
									v-for="(item, index) in allOptinos.qianjiaoHeji"
									:key="index"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-col>
					</el-row>
				</el-form-item>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">保 存</el-button>
				<el-button @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, defineEmits, inject, reactive, getCurrentInstance, ref, computed } from 'vue'
import Request from '@/http'
import { useDict } from '@/utils/hooks'
import { redLinesType } from './config'
const isShowDialog = ref(false)
const allOptinos = reactive({
	redLines: redLinesType,
	redRules: {
		CarStatus: [
			{
				label: '年检到期时间',
				value: 'AnnualAuditExpireDate',
			},
			{
				label: '交强险到期时间',
				value: 'ClivtaExpireDate',
			},
			{
				label: '商业险到期时间',
				value: 'InsuranceExpireDate',
			},
		],
		TradeBill: [
			{
				label: '车辆欠缴租金合计',
				value: 'UnpaidRent',
			},
			{
				label: '车辆欠缴押金合计',
				value: 'UnpaidDeposit',
			},
		],
		ActualTradeBill: [
			{
				label: '车辆已缴押金合计',
				value: 'PaidDeposit',
			},
		],
		CarViolate: [
			{
				label: '未处理违章分数合计',
				value: 'ViolateScore',
			},
			{
				label: '未处理违章金额合计',
				value: 'ViolateAmount',
			},
		],
	},
	// 大小
	compareArr: [
		{
			label: '大于',
			value: '>',
		},
		{
			label: '大于等于',
			value: '>=',
		},
		{
			label: '小于',
			value: '<',
		},
		{
			label: '小于等于',
			value: '<=',
		},
	],
	// 加减乘除
	calcOpts: [
		{
			label: '加',
			value: '+',
		},
		{
			label: '减',
			value: '-',
		},
		{
			label: '乘',
			value: '*',
		},
		{
			label: '除',
			value: '/',
		},
	],
	// 车辆欠缴租金合计
	qianjiaoHeji: [
		{
			label: '车辆欠缴租金合计',
			value: 'UnpaidRent',
		},
	],
})
const emit = defineEmits(['refresh'])

const isLoading = ref(false)
const companyOptions = ref([])
const statusOptions = ref([])

const formInstance = getCurrentInstance()
const $message = inject('$message')

const formInitValue = {
	companyId: '',
	type: 'CarStatus', //  红线类型
	// 临时key,非提交数据
	key2: '', // 第一规则
	key3: '', // 大于/小于...
	key4: '', // 输入框
	key5: '', // 加减乘除
	key6: '', // 车辆欠缴租金合计
}
const formData = reactive({ ...formInitValue })
const formRule = {
	companyId: [{ required: true, message: '请选择公司', trigger: 'blur' }],
	key2: [
		{
			validator: (rule, value, callback) => {
				if (!value || !formData.key3) {
					callback(new Error('选择运营红线规则'))
					return
				}
				// 找出变化的key
				if (formData.type === 'ActualTradeBill') {
					// 实收账单
					if (!formData.key5 || !formData.key6) {
						callback(new Error('选择运营红线规则'))
						return
					}
				} else if (formData.type === 'CarStatus') {
					if (!/^[1-9][0-9]{0,1}$/.test(formData.key4)) {
						callback(new Error('输入2位整数的天数'))
						return
					}
				} else {
					// 其他
					if (!/^[1-9][0-9]{0,4}$/.test(formData.key4)) {
						callback(new Error(`输入5位整数的${formData.key2 === 1 ? '分数' : '金额'}`))
						return
					}
				}
				callback()
			},
			trigger: 'blur',
		},
	],
}
const unitOptions = computed(() => {
	let placeholder = '金额',
		unit = '元'
	if (formData.type === 'CarStatus') {
		placeholder = '天数'
		unit = '天'
	} else if (formData.type === 'CarViolate' && formData.key2 === 'ViolateScore') {
		placeholder = '分数'
		unit = '分'
	}
	return {
		placeholder,
		unit,
	}
})
const formType = ref('create')

let waitUpdateId = ''
const onChangeRedType = () => {
	// 初始化
	formData.key2 = formInitValue.key2
	formData.key3 = formInitValue.key3
	formData.key4 = formInitValue.key4
	formData.key5 = formInitValue.key5
	formData.key6 = formInitValue.key6
}
function getCompanyList() {
	Request({
		url: '/suf4-user-service/company/select?isPermission=true&status=1',
		method: 'GET',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				companyOptions.value = res.data
				formInstance.refs.ruleFormRef.clearValidate(['companyIds'])
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function create() {
	isShowDialog.value = true
	formType.value = 'create'
	getCompanyList()
}

async function update(item) {
	isShowDialog.value = true
	formType.value = 'update'
	waitUpdateId = item.id
	formData.type = item.type
	formData.status = item.status
	formData.companyId = item.companyId
	const rules = JSON.parse(item.rule)
	formData.key2 = rules[0].value
	if (formData.type === 'ActualTradeBill') {
		formData.key3 = rules[1].value
		formData.key4 = rules[0].extends[1].value
		formData.key5 = rules[0].extends[0].value
		formData.key6 = rules[2].value
	} else {
		formData.key3 = rules[1].value
		formData.key4 = rules[2].value
	}
	getCompanyList()
}

async function onSubmit() {
	try {
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) throw new Error('表单校验失败, 请检查!')
		// 拼接参数
		const rules = [
			{
				type: 'topic',
				value: formData.key2,
			},
		]
		if (formData.type === 'ActualTradeBill') {
			// 实收账单
			rules[0].extends = [
				{
					type: 'symbol',
					value: formData.key5,
				},
				{
					type: 'value',
					value: formData.key4,
				},
			]
			rules.push(
				{
					type: 'symbol',
					value: formData.key3,
				},
				{
					type: 'topic',
					value: formData.key6,
				},
			)
		} else {
			rules.push(
				{
					type: 'symbol',
					value: formData.key3,
				},
				{
					type: 'value',
					value: formData.key4,
					extends: [
						{
							type: 'unit',
							value: unitOptions.value.unit,
						},
					],
				},
			)
		}

		isLoading.value = true
		let requestUrl = ''
		const data = {
			type: formData.type,
			companyId: formData.companyId,
			rule: JSON.stringify(rules),
		}
		data.companyName =
			companyOptions.value.find(i => i.companyId === formData.companyId)?.companyName || ''
		if (formType.value === 'create') {
			requestUrl = '/suf4-risk-service/redLineRule/create'
		} else {
			requestUrl = '/suf4-risk-service/redLineRule/update'
			data.id = waitUpdateId
		}
		const res = await Request({
			url: requestUrl,
			method: 'POST',
			data,
		})
		if (res.code === 200) {
			isLoading.value = false
			$message.success(`${formType.value === 'create' ? '创建' : '更新'}成功!`)
			emit('refresh')
			onClose()
		} else {
			throw new Error(res.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	Object.assign(formData, formInitValue)
	isShowDialog.value = false
}

defineExpose({ create, update })
</script>

<style lang="less">
.number-input-left {
	.el-input__inner {
		text-align: left;
	}
}
</style>
<style lang="less" scoped>
.dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
	}
}
</style>
