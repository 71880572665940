<template>
	<div class="page-container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">运营红线规则</div>
				<div class="header-button">
					<el-button
						v-permission="'pc/driver-center/contract-manage/save'"
						type="success"
						@click="onCreate"
					>
						<el-icon class="icon-plus"><Plus /></el-icon>
						新增
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="filterSetting" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					show-no
					:columns="columnSetting"
					:data="tableData"
					:params="{ page }"
				>
					<template #status="{ row }">{{ getStatusName(row.status) }}</template>
					<template #type="{ row }">{{ getRedLineName(row.type) }}</template>
					<template #operation="{ row }">
						<el-button link type="primary" @click="onEdit(row)">编辑</el-button>
						<el-button
							link
							:type="row.status === 1 ? 'warning' : 'primary'"
							@click="onDisable(row)"
						>
							{{ row.status === 1 ? '禁用' : '启用' }}
						</el-button>
						<!-- v-permission="'pc/car-center/mainten-manage/edit'" -->
						<el-button link type="danger" @click="onDelete(row)">删除</el-button>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<addFormDialog @refresh="onRefreshData" ref="formRef" />
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import addFormDialog from './addForm.vue'
import { getStatusName, getRedLineName } from './config'
export default defineComponent({
	name: 'OverTime',
})
</script>

<script setup name="OverTime">
import Request from '@/http'
import { inject, ref, getCurrentInstance, onMounted } from 'vue'
import { useFilter } from '@/utils/hooks.js'
import { filters, columns } from './config'
import { useRouter } from 'vue-router'
import usePage from '@/hooks/usePage'
import { getStore } from '@/utils/store'
import { maintenanceSelect, exportOvertimeRepairs, companySelect } from '@/api/vehicle.js'

const userInfo = getStore({ name: 'userInfo' })

const instance = getCurrentInstance()
const $message = inject('$message')
const $messageBox = inject('$messageBox')

const filterSetting = ref(filters)
const columnSetting = ref(columns)
const formRef = ref(null)
const {
	isLoading,
	filterParams,
	totalCount,
	page,
	tableData,
	onRefreshData,
	onChangeFilter,
	onSizeChange,
	onPageChange,
} = usePage(queryList)

function queryList() {
	isLoading.value = true
	const params = {
		...filterParams.value,
		page: page.value,
	}
	if (params.deferredDays === null) {
		delete params.deferredDays
	}
	Request({
		url: 'suf4-risk-service/redLineRule/paginate',
		method: 'POST',
		data: params,
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				tableData.value = res.data.records
				totalCount.value = res.data.total
				instance.refs.yiTableRef.scrollTop()
				console.log('列表获取成功 ==>', tableData)
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function onCreate() {
	formRef.value.create()
}
function onDisable(row) {
	const txt = row.status === 1 ? '禁用' : '启用'
	$messageBox
		.confirm(`确认${txt}?`, txt, {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
			type: 'warning',
		})
		.then(() => {
			Request({
				url: `suf4-risk-service/redLineRule/updateStatus`,
				method: 'POST',
				data: {
					id: row.id,
					status: Number(!row.status),
				},
			})
				.then(res => {
					if (res.code === 200) {
						onRefreshData()
						$message.success(txt + '成功')
					} else {
						isLoading.value = false
						$message.error(res.msg)
					}
				})
				.catch(error => {
					isLoading.value = false
					$message.error(error.message)
				})
		})
		.catch(() => {})
}
function onDelete(row) {
	$messageBox
		.confirm('确认删除?', '删除', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
			type: 'warning',
		})
		.then(() => {
			Request({
				url: `suf4-risk-service/redLineRule/removeById/${row.id}`,
				method: 'GET',
			})
				.then(res => {
					if (res.code === 200) {
						onRefreshData()
						$message.success('删除成功')
					} else {
						isLoading.value = false
						$message.error(res.msg)
					}
				})
				.catch(error => {
					isLoading.value = false
					$message.error(error.message)
				})
		})
		.catch(() => {})
}

function onEdit(item) {
	formRef.value.update(item)
}
function findItem(key) {
	return filterSetting.value.filters.find(item => item.attr === key)
}
async function fetchFilter() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const [companyRes] = await Promise.all([
		useFilter(() => companySelect(true), { name: 'companyName', code: 'companyId' }),
	])
	if (!companyRes.err) {
		const company = findItem('companyId')
		company.options = companyRes.data
	}
}

function initPage() {
	onRefreshData()
}

onMounted(async () => {
	fetchFilter()
	initPage()
})
</script>

<style lang="less" scoped>
.page-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.icon-plus {
		margin-right: 5px;
	}
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
</style>
